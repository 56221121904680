import { cx } from "@linaria/core";
import * as React from "react";
import { useContext } from "timvir/context";
import * as Icons from "react-feather";

/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = "div";
interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  prev?: {
    href: string;
    label: React.ReactNode;
    context: React.ReactNode;
  };
  next?: {
    href: string;
    label: React.ReactNode;
    context: React.ReactNode;
  };
}
function NavigationFooter(props: Props, ref: React.ForwardedRef<React.ComponentRef<typeof Root>>) {
  const {
    Link
  } = useContext();
  const {
    prev,
    next,
    className,
    ...rest
  } = props;
  return <Root ref={ref} {...rest} className={cx(className, classes.root)}>
      <div className={"de625n1"}>
        {prev && <Link href={prev.href} className={"l19gj27w"}>
            <Context>
              <Icons.ChevronLeft /> Previous
            </Context>
            <Label>{prev.label}</Label>
          </Link>}
        {next && <Link href={next.href} className={"l15ohs1l"}>
            <>
              <Context>
                Next <Icons.ChevronRight />
              </Context>
              <Label>{next.label}</Label>
            </>
          </Link>}
      </div>
    </Root>;
}
export default React.forwardRef(NavigationFooter);
function Label(props: {
  children: React.ReactNode;
}) {
  return <div className={"dczrd9z"} {...props} />;
}
function Context(props: {
  children?: React.ReactNode;
}) {
  return <div className={"difnzb8"} {...props} />;
}
const classes = {
  root: "rymvrdu"
};

require("./NavigationFooter.linaria.module.css!=!../../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./NavigationFooter.tsx");