"use client";

/**
 * This is documentation for the Code component.
 */
import { cx } from "@linaria/core";
import * as Page from "timvir/core";
import { useBlock } from "timvir/core";
import { codeToHtml } from "shiki";
import * as React from "react";
import * as Icons from "react-feather";
import { useImmer } from "use-immer";
import theme from "./theme";

/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = "div";
interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  /**
   * The code that should be highlighted.
   */
  children: string;

  /**
   * Language in which the code is.
   *
   * @default "markup"
   */
  language?: string;

  /**
   * When set, the code block spans the full width. Note that the text itself
   * is still aligned with the main column. Use this when you expect the text
   * to be wider than the center column.
   */
  fullWidth?: boolean;

  /**
   * The numbering starts at 1, ie. `highlightedLines={[1, 2]}` will highlight
   * the first two lines.
   */
  highlightedLines?: Array<number>;
  caption?: React.ReactNode;
}
function Code(props: Props, ref: React.ForwardedRef<React.ComponentRef<typeof Root>>) {
  const block = useBlock(props);
  const {
    children,
    language,
    fullWidth,
    highlightedLines,
    caption,
    className,
    ...rest
  } = block.props;
  const [state, mutate] = useImmer({
    settled: false,
    mouseOver: false,
    copiedToClipboard: false,
    /*
     * Prevent layout shift during (asynchronous) highlighting of the markup by
     * initializing the html witha  pre/code block with the expected number of
     * lines.
     */
    html: `<pre><code>${children.trim().split("\n").map(() => "\n").join("")}</code></pre>`
  });
  React.useEffect(() => {
    (async () => {
      const html = await codeToHtml(children.trim(), {
        lang: language ?? "text",
        themes: {
          light: "github-light",
          dark: "github-dark"
        },
        decorations: (highlightedLines ?? []).map(line => ({
          start: {
            line: line - 1,
            character: 0
          },
          end: {
            line: line,
            character: 0
          },
          properties: {
            class: classes.highlightedLine
          }
        }))
      });
      mutate(draft => {
        draft.settled = true;
        draft.html = html;
      });
    })();
  }, [mutate, children, language]);
  return <Root ref={ref} className={cx("timvir-b-Code", !state.settled && "timvir-unsettled", classes.root, fullWidth && Page.fullWidth)} {...rest}>
      <div className={cx("timvir-b-Code-container", className, theme, classes.code, fullWidth && classes.fullWidth)}>
        <div className={"d1513p2s"} onMouseEnter={() => {
        mutate(draft => {
          draft.mouseOver = true;
        });
      }} onMouseLeave={() => {
        mutate(draft => {
          draft.mouseOver = false;
          draft.copiedToClipboard = false;
        });
      }}>
          <button onClick={() => {
          navigator.clipboard.writeText(children);
          mutate(draft => {
            draft.copiedToClipboard = true;
          });
        }} className={cx("b157mkz", state.mouseOver && "b10oxtfo")}>
            <svg width={48} height={48} viewBox="0 0 48 48" className={"s1hdfi6o"}>
              <path d="M0 0 H48 V48 Z" />
            </svg>
            {state.copiedToClipboard ? <Icons.Clipboard size={"16px"} /> : <Icons.Copy size={"16px"} />}
          </button>

          <div className={cx(fullWidth ? "d17pltln" : "d793q8f")} dangerouslySetInnerHTML={{
          __html: state.html
        }} />
        </div>
      </div>

      {caption && <div className={cx("timvir-b-Code-caption", classes.caption)}>{caption}</div>}
    </Root>;
}
export default React.forwardRef(Code);
const classes = {
  root: "r9v2r0l",
  code: "cs7tint",
  fullWidth: "fjvaz2s",
  line: "lchll0h",
  highlightedLine: "h1xcko1i",
  lineNumber: "ll2b9hx",
  caption: "c5vr6r2"
};

require("./Code.linaria.module.css!=!../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Code.tsx");