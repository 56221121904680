import * as React from "react";
/**
 * The underlying DOM element which is rendered by this component.
 */
const Root = "div";
interface Props extends React.ComponentProps<typeof Root> {
  icon?: React.ReactNode;
  label: React.ReactNode;
  context?: React.ReactNode;
}
function SearchBoxListItem(props: Props, ref: React.ForwardedRef<React.ComponentRef<typeof Root>>) {
  const {
    icon,
    label,
    context,
    ...rest
  } = props;
  return <Root role="button" ref={ref} className={"r8yc6y"} {...rest}>
      <div className={"dqzmx2q"}>
        {icon}
      </div>
      <div className={"dp5wq1v"}>
        <div>{label}</div>
        {context && <div className={"du5k8uq"}>
            {context}
          </div>}
      </div>
    </Root>;
}
export default React.forwardRef(SearchBoxListItem);

require("./SearchBoxListItem.linaria.module.css!=!../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SearchBoxListItem.tsx");